.main-avalon-logo {
  background-repeat: no-repeat;
  width: 320px;
  height: 60px;
  display: block;
  background-size: cover;
  background-position: center;
  background-image: url('./img/logo.jpg');
}

.lang-drop_m {
  padding-left: 15px;
  width: 80px;

}

.lang-selector_m {
  display: block;
  width: 100%;
  padding: 0.175rem 1.25rem 0.175rem 0.55rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}