.model-block {
  width: 100%;
  height: auto;
  text-align: center;
  border: 1px solid #d5e1ed;
  transition: all ease-in-out 0.3s;
  display: grid;
  justify-items: center;
}

.text-content-block {
  align-self: end;
}

.image-block {
  max-width: 100%;
  height: auto;
}

.model-block:hover {
  opacity: 0.55;
}
