.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  max-height: calc(100vh - 100px);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  overflow-y: auto;
  margin: 0 50px;
}

.close-icon {
  font-size: 22px;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  right: 35px;
  z-index: 99999;
}

@media (max-width: 991px) {
  .modal-content {
    max-height: 100vh;
    margin: 30px 20px;
  }
}

.scrollable-content {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.fading-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust the height of the fading area as needed */
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
}
